import React from "react"
import styled, { css } from "styled-components"

import { Container as StyledContainer, Row, Col } from "../components/Grid"
import Markdown from "../components/Markdown"
import ConditionalWrapper from "../components/ConditionalWrapper"
import TextStyles from "../components/TextStyles"
import Heading from "../components/Heading"
import Button from "../components/Button"

import { media } from "../styles"
import { renderContentfulBlocks } from "../helpers/contentful"

export const Container = styled(StyledContainer)`
  padding-bottom: 40px;
  padding-top: 40px;

  ${(props) =>
    props.padding === "large" &&
    css`
      @media ${media.tablet} {
        padding-bottom: 50px;
        padding-top: 50px;
      }

      @media ${media.tabletLandscape} {
        padding-bottom: 80px;
        padding-top: 80px;
      }
    `}
`

const Text = styled.div`
  ${(props) =>
    props.limitWidth &&
    css`
      max-width: 700px;
      margin: 0 auto;
    `}

  .gatsby-image-wrapper {
    margin-bottom: 30px;
  }
`

const ButtonRow = styled.div`
  text-align: center;
  margin-top: 20px;

  @media ${media.sm} {
    margin-top: 60px;
  }
`

const TextBlock = (props) => {
  let id
  if (props.anchorId) {
    id = {
      id: props.anchorId,
    }
  }

  const columns = {
    lg: 10,
    xl: 8,
  }

  if (props.alignment === "center") {
    columns.lgOffset = 1
    columns.xlOffset = 2
  }

  return (
    <div
      className={
        props.backgroundColor && `has--background-${props.backgroundColor}`
      }
      {...id}
    >
      <TextStyles
        alignment={props.alignment}
        textColor={props.textColor}
        textSize={props.textSize}
      >
        <Container padding={props.padding}>
          <ConditionalWrapper
            condition={!props.fullWidth}
            wrapper={(children) => (
              <Row>
                <Col {...columns}>{children}</Col>
              </Row>
            )}
          >
            <ConditionalWrapper
              condition={props.alignment === "center" && props.fullWidth}
              wrapper={(children) => (
                <Row>
                  <Col {...columns}>{children}</Col>
                </Row>
              )}
            >
              {props.header && (
                <Heading type={props.headerLevel} className={props.headerColor}>
                  <span dangerouslySetInnerHTML={{ __html: props.header }} />
                </Heading>
              )}
              {props.subheader && (
                <Heading
                  type={props.subheaderLevel}
                  subHeader
                  className={props.subheaderColor}
                >
                  {props.subheader}
                </Heading>
              )}
            </ConditionalWrapper>
            {props.text && (
              <Text limitWidth={props.limitWidth}>
                {props.source === "contentful" ? (
                  renderContentfulBlocks(props.text, { ...props })
                ) : (
                  <Markdown>{props.text}</Markdown>
                )}
              </Text>
            )}
          </ConditionalWrapper>
          {props.linkText && props.linkUrl && (
            <ButtonRow>
              <Button href={props.linkUrl} kind="primary" size="large">
                {props.linkText}
              </Button>
            </ButtonRow>
          )}
        </Container>
      </TextStyles>
    </div>
  )
}

export default TextBlock
